<template>
	<div id="login">
		<common-header></common-header>
		<div class="main">
			<div class="banner">
				<img src="http://cdn.yunhjc.com/mask_group.png" alt />
			</div>
			<div class="login-box">
				<div v-show="WeChat">
					<h1 class="wx-title">微信登录</h1>
					<span class="s-title">轻重时间管理工具</span>
				</div>
				<div v-show="WeChat">
					<div class="QR-code" id="QRCode"></div>
					<div class="chang-login">
						<a @click="changLogin">使用账号登录</a>
					</div>
				</div>
				<div v-show="!WeChat">
					<h1>欢迎使用轻重缓急工具</h1>
					<div class="login-input">
						<div class="userName">
							<div class="font">
								<i class="iconfont iconuser"></i>
							</div>
							<input
								type="text"
								:placeholder="setTips"
								v-model="account"
							/>
						</div>
						<div class="password">
							<div class="font">
								<i class="iconfont iconpassword"></i>
							</div>
							<input
								:type="setType"
								:placeholder="passwordTips"
								v-model="password"
								maxlength="16"
								@keydown.enter="login"
							/>
							<el-button
								type="button"
								@click="sendcode"
								v-if="!loginType"
								class="send"
								>{{ btntxt }}</el-button
							>
						</div>
						<div class="forget" @click="openForget">忘记密码？</div>
						<div class="sign-in-btn" @click="login">登录</div>
						<div class="logon">
							<span>还没有账号？</span>
							<span @click="openLogon">马上注册</span>
						</div>
						<!--                    ***其他登录方式-->
						<!--                    切换为账号密码-->
						<div v-show="!loginType" class="other">
							<a @click="changLogin">使用账号登录</a>
						</div>
						<!--                    切换为微信/手机号-->
						<div class="other" v-show="loginType">
							<!--                            <div class="WeChat" @click="WeChat = true">-->
							<!--                                <i class="iconfont iconWechatlogin"></i>-->
							<!--                            </div>-->
							<div class="WeChat" @click="getWXQR">
								<i class="iconfont iconWechatlogin"></i>
							</div>
							<div class="phone" @click="changPhoneLogin">
								<i class="iconfont iconMobilelogin"></i>
							</div>
						</div>
						<div class="agreement" v-show="loginType">
							登录即代表您已经同意 <a>《服务协议和隐私政策》</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="copyright">
			©2020-2021轻重缓急All Rights Reserved
			<a href="https://beian.miit.gov.cn/" target="_blank"
				>粤ICP备15072947号-6
			</a>
		</div>
	</div>
</template>
<script>
/* eslint-disable */
import CommonHeader from '@/components/CommonHeader.vue'
import bind from './Bind'
export default {
	components: {
		CommonHeader,
	},
	data() {
		return {
			account: '', //手机号/邮箱
			password: '',
			disabled: false,
			textedTime: 0, // 等待60s
			btntxt: '获取验证码',
			setTips: '手机号、邮箱',
			setType: 'password',
			passwordTips: '密码',
			WeChat: false, // 是否微信登录
			loginType: true, // true = 账号密码登录
			//    微信二维码登录的参数
			appid: '',
			redirectUri: '',
			scope: '',
			state: '',
			code: '',
			retCode: '', // 扫码返回的code
			showBind: true,
		}
	},
	created() {
		this.getWxScanJs()
	},
	mounted() {
		console.log(location)
		let hash = location.hash
		this.retCode = hash.slice(13)
		console.log('code:', this.retCode)
		localStorage.code = this.retCode
		if (this.retCode) {
			this.$http({
				url: this.api.wx_login,
				data: {
					wx_login_code: this.retCode,
				},
			}).then((res) => {
				console.log(res)
				if (res.code === 0) {
					console.log('正常')
					localStorage.j_token = res.data.j_token
					localStorage.j_username = res.data.username

					this.$message.success({
						message: res.msg + '，登录时间 ' + res.data.login_at,
						center: true,
					})
					if (!localStorage.userActive) {
						localStorage.userActive = 1
						this.$router.push('/explain')
					} else if (localStorage.userActive) {
						this.$router.push('/home')
					}
				} else if (res.code === -1) {
					//    需要绑定手机号
					// this.showBind = true
					this.$router.push('/bind')
				} else {
					// 其他错误
					this.$message.warning({
						message: res.msg,
						center: true,
					})
				}
			})
		}
	},
	updated() {
		console.log(location)
		console.log(location.search)
		let hash = location.hash
		this.retCode = hash.slice(13)
	},
	methods: {
		// 获取二维码
		getWXQR() {
			this.$http({
				url: this.api.wx_login_config,
			}).then((res) => {
				if (res.code === 0) {
					console.log(res)
					this.appid = res.data.appid
					this.redirectUri = res.data.redirect_uri
					this.state = res.data.state
					let url = `https://open.weixin.qq.com/connect/qrconnect?appid=${res.data.appid}&redirect_uri=${res.data.redirect_uri}&response_type=code&scope=snsapi_login&state=${res.data.state}#wechat_redirect`
					window.location.href = url
					// this.WeChat = true
					// var obj = new WxLogin({
					//     self_redirect:true,
					//     id:"QRCode",
					//     appid: res.data.appid,
					//     scope: "snsapi_login",
					//     redirect_uri: res.data.redirect_uri,
					//     state: res.data.state,
					//     style: "",
					//     href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30NCi5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAuaW5mbyB7d2lkdGg6IDIwMHB4O30NCi5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0NCi5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9DQo="
					// });
				}
			})
		},
		getWxScanJs() {
			//    获取登录data
		},

		changLogin() {
			this.WeChat = false
			this.loginType = true
			this.setTips = '手机号、邮箱'
			this.passwordTips = '密码'
			this.setType = 'password'
			this.account = ''
			this.password = ''
		},
		changPhoneLogin() {
			this.loginType = false
			this.setTips = '手机号'
			this.passwordTips = '验证码'
			this.setType = 'text'
			this.account = ''
			this.password = ''
		},
		// 登录
		login() {
			let data = {
				account: this.account,
				login_type: this.loginType ? 1 : 2,
			}
			if (this.loginType) {
				data.password = this.password
			} else {
				data.code = this.password
			}
			this.$http({
				url: this.api.login,
				data,
			})
				.then((res) => {
					console.log('login', res)
					if (res.code === 0) {
						localStorage.j_token = res.data.j_token
						localStorage.j_username = res.data.username

						this.$message.success({
							message:
								res.msg + '，登录时间 ' + res.data.login_at,
							center: true,
						})
						if (!localStorage.userActive) {
							localStorage.userActive = 1
							this.$router.push('/explain')
						} else if (localStorage.userActive) {
							this.$router.push('/home')
						}
					} else {
						this.$message.warning({
							message: res.msg,
							center: true,
						})
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		// 发送验证码
		sendcode() {
			if (this.disabled || !this.account) return
			// 发送成功
			this.$http({
				url: this.api.get_phone_code,
				data: {
					phone: this.account,
				},
			})
				.then((res) => {
					if (res.code === 1) {
						this.$message.warning({
							message: res.msg,
							center: true,
						})
					} else if (res.code === 0) {
						this.$message.success({
							message: res.msg,
							center: true,
						})
						this.textedTime = 60
						this.disabled = true
						this.timer()
					}
					console.log('获取验证码', res)
				})
				.catch((err) => {
					console.log(err)
				})
		},
		// 60S倒计时
		timer() {
			if (this.textedTime > 0) {
				this.textedTime--
				this.btntxt = this.textedTime + 's后重新获取'
				setTimeout(this.timer, 1000)
			} else {
				this.textedTime = 0
				this.btntxt = '获取验证码'
				this.disabled = false
			}
		},
		openForget() {
			this.$router.push('/forget')
		},
		openLogon() {
			this.$router.push('/register')
		},
	},
}
</script>
<style lang="less" scoped>
#login {
	min-width: 1200px;
	height: 100vh;
	background: #f9f9f9;

	.main {
		width: 1200px;
		height: 680px;
		margin: 77px auto 0 auto;
		padding-bottom: 127px;
		display: flex;
		position: relative;
		.banner {
			width: 700px;
			height: 680px;
			background: #70ccff;
			img {
				width: 100%;
			}
		}

		.login-box {
			width: 500px;
			height: 680px;
			background: #fff;

			h1 {
				font-size: 28px;
				color: #70ccff;
				text-align: center;
				margin-top: 80px;
				font-weight: normal;
			}
			/* 微信登录 样式*/
			.wx-title {
				color: #333333;
			}
			.s-title {
				display: block;
				font-size: 12px;
				color: #5d5d5d;
				text-align: center;
				font-weight: normal;
				margin-top: 10px;
			}
			#QRCode {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.wx-tips {
				display: block;
				font-size: 12px;
				color: #5d5d5d;
				text-align: center;
				font-weight: normal;
			}
			.chang-login {
				/*margin-top: 100px;*/
				text-align: center;
				a {
					color: #70ccff;
					font-size: 16px;
					padding: 5px;
					cursor: pointer;
				}
			}
			/* 微信登录 end*/
			.login-input {
				width: 336px;
				margin: 100px auto 0 auto;

				.userName,
				.password {
					position: relative;
					.font {
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						margin: auto;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
				input::-webkit-input-placeholder,
				textarea::-webkit-input-placeholder {
					color: #c1c1c1;
				}

				input:-moz-placeholder,
				textarea:-moz-placeholder {
					color: #c1c1c1;
				}

				input::-moz-placeholder,
				textarea::-moz-placeholder {
					color: #c1c1c1;
				}

				input:-ms-input-placeholder,
				textarea:-ms-input-placeholder {
					color: #c1c1c1;
				}

				input {
					font-size: 16px;
					width: 100%;
					padding: 10px 10px 10px 32px;
					background: none;
					outline: none;
					border: none;
					border-bottom: 1px solid #d3d3d3;
					box-sizing: border-box;
				}

				.iconfont {
					color: #5d5d5d;
					font-size: 22px;
				}

				.password {
					margin-top: 10px;
					position: relative;
					.send {
						line-height: 0;
						width: 120px;
						height: 30px;
						background: #70ccff;
						font-size: 14px;
						color: #fffefe;
						text-align: center;
						position: relative;
						left: 245px;
						bottom: 36px;
						border: none;
						display: flex !important;
						align-items: center !important;
						justify-content: center !important;
						span {
							display: block;
							/*width: 120px;*/
							height: 30px;
							line-height: 30px;
						}
					}
					.ncolor {
						background: #d3d3d3;
					}
					.el-button--button {
						display: inline-block;
						width: initial;
						font-size: 16px;
						padding: 10px 5px;
						height: 16px;
						position: absolute;
						bottom: 10px;
						right: 0;
						left: initial !important;
						background: none;
						color: #999999;
						border-left: 2px solid;
						border-radius: 0;
					}
				}

				.forget {
					font-size: 14px;
					font-family: Source Han Sans SC Regular,
						Source Han Sans SC Regular-Regular;
					font-weight: 400;
					float: right;
					color: #87d9ff;
					cursor: pointer;
					margin-top: 10px;
				}

				.sign-in-btn {
					margin-top: 50px;
					width: 100%;
					height: 42px;
					font-size: 18px;
					line-height: 42px;
					border-radius: 6px;
					text-align: center;
					color: #fffefe;
					background-image: linear-gradient(0deg, #70ccff, #839eff);
					filter: drop-shadow(0px 0px 20px rgba(112, 204, 255, 0.5));
					cursor: pointer;
				}

				.logon {
					font-size: 14px;
					font-family: Source Han Sans SC Regular,
						Source Han Sans SC Regular-Regular;
					font-weight: 400;
					text-align: left;
					color: #5d5d5d;
					margin-top: 14px;

					span:last-of-type {
						color: #87d9ff;
						margin-left: 2px;
						cursor: pointer;
					}
				}
				.other {
					text-align: center;
					margin-top: 80px;
					display: flex;
					justify-content: center;
					align-items: center;

					a {
						cursor: pointer;
						color: #70ccff;
						padding: 5px;
					}
					.WeChat {
						margin-right: 56px;
					}
					.phone {
					}
					.WeChat,
					.phone {
						width: 40px;
						height: 40px;
						border-radius: 50%;
						box-sizing: border-box;
						/*border: 2px solid #999999;*/
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						.iconfont {
							font-size: 40px;
						}
					}
				}
				/*    协议*/
				.agreement {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 50px;
					font-size: 14px;
					color: #5d5d5d;
					a {
						color: #70ccff;
						cursor: pointer;
					}
				}
			}
		}
	}
}
.copyright {
    margin-top: 280px;
	box-sizing: border-box;
	text-align: center;
	padding: 30px 0;
	font-size: 14px;
}
</style>
